import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getEntireOrganList } from '../../api/get/getOrgans';
import { getMember } from '../../api/get/getMember';
import './Home.css';
import OrganCard from '../../components/organ/OrganCard';
import Searching from '../../components/search/Searching';
import LayoutHeader from '../../components/layout/LayoutHeader';
import Loading from '../../components/miscellaneous/Loading';
import Footer from '../../components/layout/LayoutFooter';
import { Helmet } from 'react-helmet';

import useScroll from '../../hooks/useScroll'; // 무한 스크롤 훅

const Home = () => {
    const [visibleMembers, setVisibleMembers] = useState([]);
    const [startIndex, setStartIndex] = useState(0);
    const [filteredMembers, setFilteredMembers] = useState([]);
    const [allMembers, setAllMembers] = useState([]);
    const BATCH_SIZE = 30;
    const navigate = useNavigate();

    const { data: entireOrganData, isLoading: organLoading, error: organError } = useQuery('entireOrganList', getEntireOrganList);
    const { data: memberData, isLoading: memberLoading, error: memberError } = useQuery('memberList', getMember);

    // NOTE 케어매니저 멤버와 전체기관 머지
    useEffect(() => {
        if (!organLoading && !memberLoading && entireOrganData && memberData) {
            const currentDate = new Date();
            
            const validMembers = memberData.filter(member => {
                if (!member.mem_expiration_date || member.mem_expiration_date === '-') return true;
                const expirationDate = new Date(member.mem_expiration_date);
                return expirationDate > currentDate;
            });

            const mergedData = entireOrganData.map(organ => {
                const member = validMembers.find(m => m.ltcAdminSym === organ.ltcAdminSym);
                return member ? {
                    ...organ,
                    ...member,
                    mem_address1: organ.mem_address1,
                    start: organ.start !== '-' ? organ.start : member.start,
                    score: organ.score !== '-' ? organ.score : member.score,
                    mem_expiration_date: member.mem_expiration_date || '-'
                } : organ;
            });

            validMembers.forEach(member => {
                if (!entireOrganData.find(organ => organ.ltcAdminSym === member.ltcAdminSym)) {
                    mergedData.push(member);
                }
            });

            mergedData.sort((a, b) => {
                if (b.mem_id && !a.mem_id) return 1;
                if (!b.mem_id && a.mem_id) return -1;
                return (b.score !== '-' ? b.score : 0) - (a.score !== '-' ? a.score : 0);
            });

            setAllMembers(mergedData);

            const seoulMembers = mergedData.filter(member => 
                member.mem_address1.startsWith('서울시') && 
                member.services.includes('방문요양')
            );
            
            setFilteredMembers(seoulMembers);
        }
    }, [organLoading, memberLoading, entireOrganData, memberData]);

    // NOTE 최초 그릴때, filteredMembers바뀔때 다시 그리기
    useEffect(() => {
        if (!organLoading && !memberLoading && filteredMembers.length) {
            setVisibleMembers(filteredMembers.slice(0, BATCH_SIZE));
            setStartIndex(BATCH_SIZE);
        }
    }, [organLoading, memberLoading, filteredMembers, BATCH_SIZE]);

    const handleCardClick = (ltcAdminSym) => {
        const searchValueElement = document.querySelector('.search-value.category');
        const searchValue = searchValueElement ? searchValueElement.textContent : '전체';
        navigate(`/organ-detail/${ltcAdminSym}?category=${searchValue}`);
    };

    // NOTE 검색기능, Searching 컴포넌트에 검색조건 전달
    /**
     * @selectedArea - 찾을 지역
     * @selectedType - 급여종(요양, 목욕, 간호)
     * @selectedOrder - 정렬 순서(점수 높은 순, 낮은 순)
     */
    const handleSelectArea = useCallback((selectedArea, selectedType, selectedOrder) => {
        let filtered = [...allMembers];

        if (selectedArea.length > 0) {
            if (selectedArea.length === 1) {
                if (selectedArea[0] === '광주시') {
                    filtered = filtered.filter(member =>
                        member.mem_address1.startsWith('광주시') && !member.mem_address1.includes('경기도')
                    );
                } else {
                    filtered = filtered.filter(member =>
                        member.mem_address1.includes(selectedArea[0])
                    );
                }
            } else if (selectedArea.length === 2) {
                filtered = filtered.filter(member =>
                    selectedArea.every(area => member.mem_address1.includes(area))
                );
            } else if (selectedArea.length === 3) {
                const lastArea = selectedArea[selectedArea.length - 1].split(' ').pop();
                filtered = filtered.filter(member =>
                    selectedArea.slice(0, -1).every(area => member.mem_address1.includes(area)) &&
                    member.mem_address1.includes(lastArea)
                );
            }
        }
        
        if (selectedType !== '전체') {
            const typeKeywords = [
                '노인요양시설(개정법)','방문요양','방문목욕','방문간호','주야간보호','단기보호','복지용구','치매전담',
            ];

            filtered = filtered.filter(member =>
                typeKeywords.some(keyword => member.services.includes(keyword) && keyword.includes(selectedType))
            );
        }

        const sortMembers = (members, order) => {
            const withMemId = members.filter(member => member.mem_id);
            const withoutMemId = members.filter(member => !member.mem_id);

            if (order === '점수 높은 순') {
                withMemId.sort((a, b) => (b.score !== '-' ? b.score : 0) - (a.score !== '-' ? a.score : 0));
                withoutMemId.sort((a, b) => (b.score !== '-' ? b.score : 0) - (a.score !== '-' ? a.score : 0));
            } else {
                withMemId.sort((a, b) => (a.score !== '-' ? a.score : 0) - (b.score !== '-' ? b.score : 0));
                withoutMemId.sort((a, b) => (a.score !== '-' ? a.score : 0) - (b.score !== '-' ? b.score : 0));
            }

            return [...withMemId, ...withoutMemId];
        };

        filtered = sortMembers(filtered, selectedOrder);

        setFilteredMembers(filtered);
        setStartIndex(0);
        setVisibleMembers(filtered.slice(0, BATCH_SIZE));
    }, [allMembers, BATCH_SIZE]);

    // NOTE 무한스크롤 useScroll 훅 사용
    const loadMoreMembers = useCallback(() => {
        if (startIndex >= filteredMembers.length) return;

        const newVisibleMembers = filteredMembers.slice(startIndex, startIndex + BATCH_SIZE).map(member => ({
            ...member,
            start: member.start.includes('T') ? member.start.split('T')[0] : member.start
        }));
        setVisibleMembers(prev => [...prev, ...newVisibleMembers]);
        setStartIndex(prev => prev + BATCH_SIZE);
    }, [startIndex, filteredMembers, BATCH_SIZE]);
    useScroll(loadMoreMembers);

    // NOTE 로딩 세트
    if (organLoading || memberLoading) {return <Loading message="" />;}
    if (organError || memberError) {return <Loading message={`데이터를 불러오는 데 실패했습니다: ${organError?.message || memberError?.message}`} />;}

    return (
        <div className="container">
            <Helmet>
                <title>요양 알리미</title>
                <meta name="description" content="요양 알리미에서 내 지역의 믿을 수 있는 방문요양기관을 찾아보세요. 방문요양, 방문간호, 방문목욕 등 맞춤형 재가요양 서비스 정보를 한눈에 확인할 수 있습니다." />
                <meta name="keywords" content="요양, 알리미, 요양 알리미, 요양기관 찾기, 방문요양, 재가요양, 방문간호, 방문목욕, 노인요양, 요양보호사, 장기요양, 노인장기요양보험" />
                
                {/* Open Graph 태그 */}
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="요양 알리미" />
                <meta property="og:title" content="요양 알리미 | 맞춤형 요양기관 찾기 서비스" />
                <meta property="og:description" content="요양 알리미에서 내 지역의 믿을 수 있는 방문요양기관을 찾아보세요. 방문요양, 방문간호, 방문목욕 등 맞춤형 재가요양 서비스 정보를 한눈에 확인할 수 있습니다." />
                
                {/* 추가 SEO 태그 */}
                <meta name="robots" content="index,follow" />
                <meta name="author" content="요양 알리미" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.yoyangalimi.com/" />
            </Helmet>

            <LayoutHeader />

            <main className="content-wp">
                <section className="intro-wp">
                    <div className="intro-content">
                        <div className="intro-description">
                            <h2>대표님의 지역 경쟁력 확보와</h2>
                            <p>필요한 요양 정보, 요양보호사 구인, 구직 모두 한곳에서 확인할 수 있어요!!</p>
                        </div>
                        <div className="intro-character" alt="Character" />
                    </div>
                </section>
                
                <Searching onSelectArea={handleSelectArea} />

                <section className="total-count">
                    <p>총 <span className="">{filteredMembers.length.toLocaleString()}</span>개의 시설이 있어요.</p>
                    <p>마음에 드는 시설을 찾아 부담없이 비대면 상담을 해보아요.</p>
                </section>

                <section className="list-wp" id="card-container">
                    {visibleMembers.length > 0 ? (
                        visibleMembers.map((member, index) => (
                            <div
                                key={index}
                                className="organ-card"
                                data-ltcadminsym={member.ltcAdminSym}
                                onClick={() => handleCardClick(member.ltcAdminSym)}
                            >
                                <OrganCard member={member} />
                            </div>
                        ))
                    ) : (
                        <div>해당하는 기관 정보가 없습니다.</div>
                    )}
                </section>
            </main>

            <Footer />
        </div>
    );
};

export default Home;